import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../Shared/Services/Api.service';
import { ConfigService } from '../auth/services/config.service';
import {
  PointTransaction,
  RewardAccountBalance,
  RewardConfig,
} from '../rewards/reward.config';
import { BehaviorSubject, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RewardService extends ApiService {
  baseUrl = `${environment.orderUrl}/rewards`;
  private rewardconfig = new BehaviorSubject<RewardConfig | null>(null);
  private balance = new BehaviorSubject<RewardAccountBalance | null>(null);
  rewardConfig$ = this.rewardconfig.asObservable();
  constructor(
    httpClient: HttpClient,
    private config: ConfigService // private fireStore: Firestore
  ) {
    super(httpClient);
  }

  getConfig() {
    const config = this.rewardconfig.value;
    if (config && config != null) {
      return of(config);
    }
    return this.GetAll<RewardConfig>(`${this.baseUrl}/config`).pipe(
      map((res) => {
        this.rewardconfig.next(res.payload);
        return res.payload;
      })
    );
  }

  getBalance(reload: boolean = false) {
    const balance = this.balance.value;
    if (balance && !reload) return of(balance);
    return this.GetAll<RewardAccountBalance>(`${this.baseUrl}/balance`).pipe(
      map((result) => {
        this.balance.next(result.payload);
        return result.payload;
      })
    );
  }

  getTransactions() {
    return this.GetAll<PointTransaction[]>(`${this.baseUrl}/transactions`);
  }

  verifyReferralCode(code: string) {
    return this.GetAll<boolean>(`${this.baseUrl}/verify-referral-code/${code}`);
  }
}
