import { Challenges } from 'src/app/Shared/models/challenge.model';

export interface Checkout {
  storeId: string;
  storeName: string;
  phoneNumber: string;
  deliveryMethod: DeliveryOptionsEnum;
  paymentMethod: PaymentMethod;
  items: CheckoutItem[];
  address: CheckoutAddress;
  isMobile: boolean;
  promoCode?: string;
  redeemPoints: number;
  referralCode?: string;
}

export interface CheckoutItem {
  productId: number;
  productName: string;
  price: number;
  oldPrice: number;
  quantity: number;
  pictureUrl: string;
  productOptions: CheckoutProductOption[];
}

export interface PaymentMethod {
  name: PaymentOptionEnum;
  value: string;
  bankCode: string;
  reference: string;
  challenges: Challenges[];
}

export interface CheckoutAddress {
  name: string;
  landMark: string;
  addressLine: string;
  city: string;
  cityCode: string;
  dropOffInstruction: string;
  deliveryInstruction: string;
  isDefault: boolean;
  lat: number;
  long: number;
}

export interface CheckoutProductOption {
  id: number;
  name: string;
  variations: CheckoutProductVariations[];
}

export interface ApplyDiscount {
  orderTotal: number;
  deliveryFee: number;
  promoCode: string;
}

export interface Discount {
  name: string;
  discountType: number;
  amount: number;
  code: string;
}

export interface CheckoutProductVariations {
  productOptionId: number;
  productOptionName: string;
  id: number;
  name: string;
  price: number;
  oldPrice: number;
  quantity: number;
}

export enum DeliveryMethod {
  Standard = 1,
  Scheduled = 2,
  DeliveryOptionsEnum,
}

export enum PaymentOptionEnum {
  Cash = 1,
  Card = 2,
  Account = 3,
  Loan = 4,
  Transfer = 5,
}

export enum DeliveryOptionsEnum {
  Delivery = 1,
  PickUp = 2,
}
