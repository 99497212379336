import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthOptions,
  LogLevel,
  LogoutAuthOptions,
  OidcSecurityService,
  OpenIdConfiguration,
} from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable, map, switchMap, of } from 'rxjs';
import { IdentityAuthModel } from '../models/auth.model';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/Shared/Services/token-storage';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ConfigService } from './config.service';
import { Browser } from '@capacitor/browser';
import { ApiService } from 'src/app/Shared/Services/Api.service';
import { BuyerProfile } from '../models/buyer.profile';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends ApiService {
  baseUrl = `${environment.orderUrl}/user`;
  httpOptions: { headers: HttpHeaders } | undefined;
  accessToken: any;
  authData: IdentityAuthModel = {
    countryDialCode: '',
    CountryCode: '',
    email: '',
    family_name: '',
    given_name: '',
    id: '',
    OrganizationId: '',
    phone_number: '',
    preferred_username: '',
    sub: '',
    isAuthenticated: false,
    profile_picture: '',
    accessToken: '',
  };

  private currentUserSubject = new BehaviorSubject<IdentityAuthModel>(
    this.authData
  );
  currentUser$: Observable<IdentityAuthModel>;
  constructor(
    public oidcSecurityService: OidcSecurityService,
    private router: Router,
    private platform: Platform,
    private config: ConfigService,
    httpClient: HttpClient
  ) {
    super(httpClient);
    this.currentUser$ = this.currentUserSubject.asObservable();
  }

  sendAuthenticationStatus(authData: IdentityAuthModel) {
    this.currentUserSubject.next(authData); //Triggering an event
  }

  deleteAccount() {
    return this.GetAll<boolean>(
      `${environment.authority}/api/v1/account/delete-account`
    ).pipe(
      map((result) => {
        this.logout();
        return result;
      })
    );
  }
  /**
   *
   * @returns gets User profile data
   */
  // public loadUserProfile(url: string | undefined = undefined): void {
  //   this.oidcSecurityService
  //     .checkAuth(url)
  //     .subscribe(({ isAuthenticated, userData, accessToken }) => {
  //       if (
  //         isAuthenticated != null &&
  //         isAuthenticated != undefined &&
  //         isAuthenticated == true
  //       ) {

  //         var data = userData as IdentityAuthModel;
  //         data.isAuthenticated = isAuthenticated;
  //         data.accessToken = accessToken;
  //         this.loadUserOrderProfile().subscribe(res=> {
  //         data.referralCode=res.payload.referralCode
  //         });
  //         this.currentUserSubject.next(data);
  //       } else {
  //         this.currentUserSubject.next(this.authData);
  //         localStorage.clear();
  //         //this.tokenStorage.clear();
  //       }
  //     });
  // }

  public loadUserProfile(url?: string): void {
    this.oidcSecurityService
      .checkAuth(url)
      .pipe(
        // Switch to the user order profile stream if authenticated
        switchMap(({ isAuthenticated, userData, accessToken }) => {
          if (isAuthenticated) {
            const data = userData as IdentityAuthModel;
            data.isAuthenticated = isAuthenticated;
            data.accessToken = accessToken;

            // Return combined stream of user data and order profile
            return this.loadUserOrderProfile().pipe(
              map((res) => ({
                ...data,
                referralCode: res.payload.referralCode,
              }))
            );
          }

          // Return null if not authenticated
          return of(null);
        })
      )
      .subscribe((data) => {
        if (data) {
          this.currentUserSubject.next(data);
        } else {
          this.currentUserSubject.next(this.authData);
          localStorage.clear();
        }
      });
  }
  /**
   *
   * @returns is user authenticated
   */
  public isAuthenticated(): Observable<boolean> {
    this.loadUserProfile();
    return this.oidcSecurityService.isAuthenticated();
  }

  /**
   * User sign in by redirecting to Identity server
   */
  public login(): void {
    if (this.config.isMobile()) {
      this.oidcSecurityService.getConfiguration().subscribe((data) => {
        localStorage.setItem('configId', data.configId ?? '');
        this.oidcSecurityService.authorize(data.configId, {
          urlHandler: (url) => Browser.open({ url, windowName: '_self' }),
        });
      });
    } else {
      this.oidcSecurityService.authorize();
    }
  }

  loginWithPopup() {
    const somePopupOptions = { width: 500, height: 500, left: 50, top: 50 };

    const authOptionsOrNull =
      /* ... */

      this.oidcSecurityService
        .authorizeWithPopUp()
        .subscribe(
          ({ isAuthenticated, userData, accessToken, errorMessage }) => {
            if (
              isAuthenticated != null &&
              isAuthenticated != undefined &&
              isAuthenticated == true
            ) {
              var data = userData as IdentityAuthModel;
              data.isAuthenticated = isAuthenticated;
              data.accessToken = accessToken;
              this.currentUserSubject.next(data);
            } else {
              this.currentUserSubject.next(this.authData);
              localStorage.clear();
            }
          }
        );
  }
  /**
   * logs off users
   */
  public logout(): void {
    const authOptions: LogoutAuthOptions = {
      customParams: {
        post_logout_redirect_uri: 'com.getnoddit.shop://',
      },
    };

    if (this.config.isMobile()) {
      const authOptions: LogoutAuthOptions = {
        urlHandler: (url) => Browser.open({ url, windowName: '_self' }),
      };

      this.oidcSecurityService.logoff(undefined, authOptions).subscribe(() => {
        this.currentUserSubject.next(this.authData);
        localStorage.clear();
      });
    } else {
      this.oidcSecurityService.logoff().subscribe(() => {
        this.currentUserSubject.next(this.authData);
        localStorage.clear();
      });
    }
  }

  /**
   *
   * @returns gets the Access token from Identity Server
   */
  public getToken(): any {
    return this.oidcSecurityService.getAccessToken();
  }

  getRedirectUrl() {
    if (
      (this.platform.is('mobile') && !this.platform.is('hybrid')) ||
      this.platform.is('desktop')
    ) {
      return window.location.origin;
    }
    return '';
  }
  loadUserOrderProfile() {
    return this.GetAll<BuyerProfile>(`${this.baseUrl}/me`);
  }
}
