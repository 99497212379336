import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RewardService } from 'src/app/services/reward.service';

@Component({
  templateUrl: '../views/referral.html',
  // styleUrls:['../styles/transfer-info.scss']
})
export class ReferralPage implements OnInit, OnDestroy {
  @Input() deliveryFee = 0;
  @Input() orderTotal = 0;
  @Input() code?: string;
  form?: FormGroup;
  isVerified: boolean = false;
  unsubscribe: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private rewardService: RewardService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      code: [this.code],
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  verifyCode() {
    const code = this.form?.get('code')?.value;
    const discountSub = this.rewardService.verifyReferralCode(code).subscribe({
      next: (res) => {
        this.isVerified = res.payload;
        if (res.payload) {
          this.code = code;
        }
      },
    });
    this.unsubscribe.push(discountSub);
  }

  submit() {
    this.modalCtrl.dismiss(this.code, 'confirm');
  }
}
